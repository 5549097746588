import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-game-settings-information',
  templateUrl: './game-settings-information.component.html',
  styleUrls: ['./game-settings-information.component.scss']
})
export class GameSettingsInformationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
