<div class="slogan">Your game. Your competitions.</div>
<button class="button-c1" (click)="gameService.addPlayer()">add player</button>
<button class="button-c2" (click)="gameService.removeLastPlayer()">
  remove player
</button>

<div *ngFor="let player of gameService.game.players; let index = index">
  <input
    type="text"
    [(ngModel)]="gameService.game.players[index].name"
    (change)="gameService.saveGame()"
  />
</div>

<button class="button-c3" (click)="gameService.startGame()">
  Start the game!
</button>

<app-game-settings-information></app-game-settings-information>
