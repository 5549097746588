import { Component, OnInit } from '@angular/core';
import { GameService } from '../../services/game.service';
import { ResultService } from '../../services/result.service';
import { Player } from '../../models/player';

export enum GameScorePadStateType {
  List = 0,
  AddPoints = 1,
}

@Component({
  selector: 'app-game-score-pad',
  templateUrl: './game-score-pad.component.html',
  styleUrls: ['./game-score-pad.component.scss'],
})
export class GameScorePadComponent implements OnInit {
  constructor(private gameService: GameService) {
    this.state = GameScorePadStateType.List;
  }
  eGameScorePadStateType = GameScorePadStateType;
  currentPlayer: Player | undefined;

  state: GameScorePadStateType;

  ngOnInit(): void {}

  addPointStarted(player: Player): void {
    this.currentPlayer = player;
    this.state = GameScorePadStateType.AddPoints;
  }

  addPointsAddPoints(points: number): void {
    this.gameService.addPoints(this.currentPlayer as Player, points);
    this.state = GameScorePadStateType.List;
  }

  addPointsCancel(): void {
    this.state = GameScorePadStateType.List;
  }

  addPointsPassed(): void {
    console.log('addPointsPassed');
    this.gameService.addPassed(this.currentPlayer as Player);
    this.state = GameScorePadStateType.List;
  }
}
