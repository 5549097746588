<div id="wrapper">
  <div id="header">
    <strong class="logo">keeping score</strong>
  </div>
  <div id="contentliquid">
    <div id="content">
      <app-game-settings
        *ngIf="gameService.game.state === eGameStateType.Setting"
      ></app-game-settings>

      <app-game-score-pad
        *ngIf="gameService.game.state === eGameStateType.Game"
      ></app-game-score-pad>

      <app-game-summary
        *ngIf="gameService.game.state === eGameStateType.Summary"
      >
      </app-game-summary>
    </div>
  </div>
  <div id="footer">
    <p></p>
  </div>
</div>
