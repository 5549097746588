import { Injectable } from '@angular/core';
import { Game } from '../models/game';
import { Result, ResultRow } from '../models/result';
import { Turn } from '../models/turn';
import { Summary } from '../models/summary';
import { TimeHelper } from '../helpers/time-helper';

@Injectable({
  providedIn: 'root',
})
export class ResultService {
  constructor() {}
  timerHelper = new TimeHelper();
  minRowsCount = 7;

  private turnsCount(game: Game): number {
    const result = new Array<any>();

    game.players.forEach((p) => {
      result.push({ playerId: p.id, turns: 0 });
    });

    game.turns.forEach((t) => {
      const res = result.find((p) => p.playerId === t.playerId);
      res.turns += 1;
    });

    return Math.max(...result.map((p) => p.turns), 0);
  }

  getResultFromGame(game: Game): Result {
    const playersCount = game.players.length;
    const result = new Result();
    let resultRow: ResultRow = new ResultRow(playersCount);

    let currentPlayerId = Number.MAX_SAFE_INTEGER;
    const sumPoints = new Array(game.players.length).fill(0);

    for (const turn of game.turns) {
      if (currentPlayerId >= turn.playerId) {
        resultRow = new ResultRow(playersCount);
        result.rows.push(resultRow);
      }
      currentPlayerId = turn.playerId;

      sumPoints[currentPlayerId] =
        sumPoints[currentPlayerId] + (turn.passed ? 0 : turn.points);

      resultRow.cells[currentPlayerId].value = turn.passed
        ? '-'
        : sumPoints[currentPlayerId];
      resultRow.cells[currentPlayerId].pointInfo = this.getPointInfo(turn);
      resultRow.cells[currentPlayerId].timeInfo = this.getTimeInfo(turn);
    }

    for (let i = result.rows.length; i < this.minRowsCount; i++) {
      result.rows.push(new ResultRow(playersCount));
    }

    return result;
  }

  private getPointInfo(turn: Turn): string {
    if (turn.passed) {
      return '';
    }
    const points = turn.points as number;

    if (points <= 0) {
      return points.toString();
    } else {
      return `+${points}`;
      return `+${points}`;
    }
  }

  private getTimeInfo(turn: Turn): string | null {
    return this.timerHelper.getTurnTime(turn.startDate, turn.endDate);
  }

  public GetSummaryFromGame(game: Game): Array<Summary> {
    let result = Array<Summary>();

    game.players.forEach((player) => {
      result.push(new Summary(player, 0, 0, 0, ''));
    });

    game.turns.forEach((turn) => {
      const resultItem = result.find((p) => p.player.id === turn.playerId);
      if (resultItem) {
        resultItem.points += turn.points ?? 0;
        resultItem.time += this.turnTimeInSeconds(turn);
      }
    });

    result = result.sort((a, b) => b.points - a.points);

    if (game.invertedScores) {
      result = result.reverse();
    }

    let place = 1;
    let lastPoints = result[0].points;
    result.forEach((p) => {
      if (lastPoints !== p.points) {
        lastPoints = p.points;
        place++;
      }
      p.place = place;
      p.timeInfo = this.timerHelper.getTurnTimeFromSeconds(p.time);
    });

    return result;
  }

  turnTimeInSeconds(turn: Turn): number {
    const seconds =
      (new Date(turn.endDate).getTime() - new Date(turn.startDate).getTime()) /
      1000;
    return seconds;
  }
}
