import { Component } from '@angular/core';
import { GameService } from './services/game.service';
import { Game } from './models/game';
import { GameStateType } from './models/game';
import { Player } from './models/player';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  eGameStateType = GameStateType;

  constructor(public gameService: GameService) {}

  public get gameState(): GameStateType {
    return this.gameService.game.state;
  }

  title = 'score-pad';
}
