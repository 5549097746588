import { Injectable } from '@angular/core';
import { Game, GameStateType } from '../models/game';
import { Config } from '../config';
import { Player } from '../models/player';
import { Turn } from '../models/turn';

@Injectable({
  providedIn: 'root',
})
export class GameService {
  game: Game;

  constructor() {
    const game = this.loadGame();

    if (game) {
      this.game = game;
    } else {
      this.game = new Game();
      this.game.players.push(new Player(0, $localize`player 1`));
      this.game.players.push(new Player(0, $localize`player 2`));
      this.prepareIdsForPlayers();
    }
  }

  loadGame(): Game | null {
    const gameJson = localStorage.getItem(Config.localStorageKeyForGame);
    if (gameJson !== null) {
      return JSON.parse(gameJson) as Game;
    } else {
      return null;
    }
  }

  saveGame(): void {
    localStorage.setItem(
      Config.localStorageKeyForGame,
      JSON.stringify(this.game)
    );
  }

  addPlayer(): void {
    const player = new Player(-1, $localize`new player`);
    this.game.players.push(player);
    this.prepareIdsForPlayers();
    this.saveGame();
  }

  removeLastPlayer(): void {
    if (this.game.players.length > 1) {
      this.game.players.pop();
      this.prepareIdsForPlayers();
      this.saveGame();
    }
  }

  private prepareIdsForPlayers(): void {
    // if (this.game) {
    this.game.players.forEach((p, i) => (p.id = i));
    // }
  }

  startGame(): void {
    this.game.state = GameStateType.Game;

    const date = new Date();

    this.game.startDate = date;
    this.game.lastTurnDate = date;
    this.saveGame();
  }

  endGame(): void {
    if (this.game.turns.length === 0) {
      this.game.state = GameStateType.Setting;
    } else {
      this.game.state = GameStateType.Summary;
    }

    this.saveGame();
  }

  continueGame(): void {
    this.game.state = GameStateType.Game;
    this.saveGame();
  }

  settingNewGame(): void {
    this.game.turns = [];
    this.game.state = GameStateType.Setting;
    this.saveGame();
  }

  undoLastEntry(): void {
    this.game.turns.pop();
    this.saveGame();
  }

  addPoints(player: Player, points: number): void {
    const date = new Date();

    this.game.turns.push(
      new Turn(player.id, this.game.lastTurnDate, date, points)
    );
    this.game.lastTurnDate = date;
    this.saveGame();
  }

  addPassed(player: Player): void {
    const date = new Date();
    this.game.turns.push(
      new Turn(player.id, this.game.lastTurnDate, date, 0, true)
    );
    this.game.lastTurnDate = date;
    this.saveGame();
  }

  public get currentPlayer(): Player {
    if (this.game.turns.length === 0) {
      return this.game.players[0];
    }
    const lastTurn = this.game.turns[this.game.turns.length - 1];
    const lastPlayer = this.game.players.find(
      (p) => p.id === lastTurn.playerId
    );

    if (lastPlayer === undefined) {
      return this.game.players[0];
    }

    if (lastPlayer.id === this.game.players.length - 1) {
      return this.game.players[0];
    } else {
      return this.game.players[lastPlayer.id + 1];
    }
  }

  toggleShowDetailInScorePad(): void {
    this.game.showDetailInScorePad = !this.game.showDetailInScorePad;
    this.saveGame();
  }

  toggleShowTimerInScorePad(): void {
    this.game.showTimerInScorePad = !this.game.showTimerInScorePad;
    this.saveGame();
  }

  changeInvertedScore(): void {
    this.game.invertedScores = !this.game.invertedScores;
  }
}
