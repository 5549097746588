export class Result {
  rows: Array<ResultRow> = new Array<ResultRow>();
}

export class ResultRow {
  constructor(cellCount: number) {
    for (let i = 0; i < cellCount; i++) {
      this.cells.push(new ResultCell(null, null, null));
    }
  }

  cells: Array<ResultCell> = new Array<ResultCell>();
}

export class ResultCell {
  constructor(
    public value: string | null,
    public pointInfo: string | undefined | null,
    public timeInfo: string | undefined | null
  ) {}
}
