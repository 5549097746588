export class Player {
  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
    this.points = 0;
    this.gameTime = 0;
  }
  id: number;
  name: string;
  points: number;
  gameTime: number;
}
