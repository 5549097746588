<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<h1>{{ currentPlayer?.name }}</h1>

<input
  [(ngModel)]="points"
  #inputPoints
  (keypress)="onlyNumber($event)"
  type="number"
  min="-99999"
  max="99999"
/>

<button
  class="button-c1"
  (click)="addPoints()"
  [disabled]="inputPoints.value === '' || inputPoints.value === '-'"
>
  Add points
</button>
<button (click)="passed()">Player passed</button>
<button class="button-c2" (click)="cancel()">Cancel</button>
<button
  (click)="toggle()"
  [disabled]="inputPoints.value === '' || inputPoints.value === '-'"
>
  +/-
</button>
