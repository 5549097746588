import { Player } from './player';

export class Turn {
  constructor(
    public playerId: number,
    public startDate: Date,
    public endDate: Date,
    public points: number | null,
    public passed: boolean = false
  ) {}
}
