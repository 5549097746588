import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Player } from '../../../models/player';
import { AllowIn, ShortcutInput } from 'ng-keyboard-shortcuts';

@Component({
  selector: 'app-game-score-pad-add-points',
  templateUrl: './game-score-pad-add-points.component.html',
  styleUrls: ['./game-score-pad-add-points.component.scss'],
})
export class GameScorePadAddPointsComponent implements OnInit, AfterViewInit {
  @Output() eventCancel = new EventEmitter();
  @Output() eventPassed = new EventEmitter();
  @Output() eventAddPoints = new EventEmitter<number>();
  @Input() currentPlayer: Player | undefined;

  points = '';
  shortcuts: ShortcutInput[] = [];

  @ViewChild('inputPoints') inputPoints: ElementRef | null = null;

  constructor() {}
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.inputPoints?.nativeElement.focus();
    this.keyMappings();
  }

  private keyMappings(): void {
    this.shortcuts.push(
      {
        key: 'enter',
        preventDefault: true,
        allowIn: [AllowIn.Input],
        command: (e) => this.addPoints(),
      },
      {
        key: 'esc',
        preventDefault: true,
        allowIn: [AllowIn.Input],
        command: (e) => this.cancel(),
      }
    );
  }

  cancel(): void {
    this.eventCancel.emit();
  }

  passed(): void {
    this.eventPassed.emit();
  }

  addPoints(): void {
    // tslint:disable-next-line:radix
    let lPoints = parseInt(this.points);

    if (isNaN(lPoints)) {
      lPoints = 0;
    }

    this.eventAddPoints.emit(lPoints);
  }

  toggle(): void {
    // tslint:disable-next-line:radix
    const lPoints = parseInt(this.points);
    this.points = (lPoints * -1).toString();
  }

  onlyNumber(event: KeyboardEvent): void {
    if (
      !(event.key >= '0' && event.key <= '9') &&
      !(this.inputPoints?.nativeElement.value === '' && event.key === '-')
    ) {
      event.preventDefault();
    }
  }
}
