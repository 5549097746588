import { Player } from './player';
import { Turn } from './turn';
import { Config } from '../config';

export enum GameStateType {
  Setting = 0,
  Game = 1,
  Summary = 2,
}
export class Game {
  constructor() {
    this.startDate = new Date();
    this.players = [];
    this.turns = [];
    this.state = GameStateType.Setting;
    this.invertedScores = false;
    this.showDetailInScorePad = false;
    this.showTimerInScorePad = true;
    this.lastTurnDate = this.startDate;
  }

  state: GameStateType;
  startDate: Date;
  endDate?: Date;
  players: Player[];
  turns: Turn[];
  invertedScores: boolean;
  showDetailInScorePad: boolean;
  showTimerInScorePad: boolean;
  lastTurnDate: Date;
}
