<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>

<div class="timer" *ngIf="gameService.game.showTimerInScorePad">
  {{ turnTime }}
</div>

<table (click)="tableClick()">
  <thead>
    <tr>
      <th *ngFor="let player of gameService.game.players">
        {{ player.name
        }}<span
          class="current-player"
          *ngIf="gameService.currentPlayer === player"
          >*</span
        >
      </th>
    </tr>
  </thead>
  <ng-container *ngFor="let row of gameResult?.rows; let index = index">
    <tr>
      <td *ngFor="let player of gameService.game.players; let index = index">
        <div class="point-container">
          <div class="point">
            {{ row.cells[index].value }}
          </div>
          <div class="info">
            <div
              *ngIf="
                gameService.game.showDetailInScorePad &&
                row.cells[index].pointInfo
              "
              class="info-point"
            >
              {{ row.cells[index].pointInfo }}
            </div>
            <div
              *ngIf="
                gameService.game.showDetailInScorePad &&
                row.cells[index].timeInfo
              "
              class="info-time"
            >
              {{ row.cells[index].timeInfo }}
            </div>
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
  <tfoot>
    <tr>
      <td *ngFor="let player of gameService.game.players">
        <button class="button-c1" (click)="addPoints(player)">
          {{ player.name }}
        </button>
      </td>
    </tr>
  </tfoot>
</table>

<div #scroller>
  <button class="button-c3" (click)="endGame()">end of the game</button>
  <button (click)="undoLastEntry()">undo last entry</button>
</div>
<div class="options">
  <p-checkbox
    [checked]="gameService.game.showDetailInScorePad"
    (click)="changeShowDetailInScorePad()"
  >
    Show details of turn
  </p-checkbox>
</div>

<div class="options">
  <p-checkbox
    [checked]="gameService.game.showTimerInScorePad"
    (click)="changeShowTimerInScorePad()"
  >
    Show timer
  </p-checkbox>
</div>
