import { Player } from './player';

export class Summary {
  constructor(
    public player: Player,
    public place: number,
    public points: number,
    public time: number,
    public timeInfo: string
  ) {}
}
