<div class="information">
  <h1>Keeping score, online scoreboard for board games</h1>
  <p>
    The online scoreboard allows you to save the results of any game. It can be
    used in card games, board games, scrabble - wherever there is a need to
    record the results. The scoreboard sums up the points in the game for you
    and additionally measures the playing time of individual players. In
    addition, it works in offline mode, you do not need to be connected to the
    Internet, the results are remembered on the fly. Additionally, you can show
    off your results on facebook or twitter.
  </p>
  <h2>Game settings</h2>
  <p>
    To start the game, enter the players' initials in the fields above. The
    scoreboard also controls the order of the players, so it is worth entering
    the initials of the players in the order established by the players. If
    there are more players, add them using the <b>ADD PLAYER</b> button, if
    less, remove excess players using the <b>REMOVE PLAYER</b> button.
  </p>
  <h2>Starting the game</h2>
  <p>
    To start saving game results, start your game and then use the
    <b>START THE GAME</b> button. The currently playing player is marked with an
    orange star (next to the nickname). Additionally, the playing time of each
    player is measured. The playing player's current turn time is shown in the
    upper right corner. Clicking anywhere in the table will automatically open
    the results input window. To enter a player's score, enter the points scored
    in the field. It is also permissible to enter that player's <b>PASSED</b> as
    well as negative points. If your phone does not allow negative points, use
    the + - button. After entering the points, the game score will be updated.
    In addition to the added points, the system shows the time of a relevant
    turn, the number of points earned during the turn. If you are bothered by
    the excess of information, use the <b>SHOW DETAILS OF TURN</b> and
    <b>SHOW TIMER</b>
    checkboxes. If you have made a mistake while entering the data, use the UNDO
    LAST ENTRY button. If for some reason you want to skip a player (at his
    turn), click the other player's initials button below the scoreboard -
    points will be added for that player. end of the game,
  </p>

  <h2>Summary of the results</h2>
  <p>
    To finish the game, use the <b>END OF THE GAME</b> button. The scoreboard
    will summarize the players' achievements along with information about
    points, game time. If your board game or card game has inverted scores
    (less-best), use the "inverted scores" (less-best) checkbox. If you still
    want to continue the game, select the <b>CONTINUE PLAYING</b> button, if you
    want to start a new game, select the <b>START NEW GAME</b> button.
  </p>
</div>

<div class="adv">
  powered by:
  <div class="links">
    <table>
      <tr>
        <td><a href="https://poocoo.us" rel="nofollow">poocoo.us</a></td>
        <td>American scrabble solver</td>
      </tr>
      <tr>
        <td><a href="https://poocoo.co.uk" rel="nofollow">poocoo.co.uk</a></td>
        <td>British scrabble solver</td>
      </tr>
      <tr>
        <td><a href="https://poocoo.de" rel="nofollow">poocoo.de</a></td>
        <td>Deutsche Scrabble Hilfe, wörter suchen</td>
      </tr>
      <tr>
        <td><a href="https://poocoo.fr" rel="nofollow">poocoo.fr</a></td>
        <td>Dictionnaire Scrabble, chercher des mots, Anagrammeur</td>
      </tr>
      <tr>
        <td><a href="https://poocoo.pl" rel="nofollow">poocoo.pl</a></td>
        <td>polski słownik scrabble</td>
      </tr>
    </table>
  </div>
</div>
