import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  OnDestroy,
  ElementRef,
} from '@angular/core';
import { GameService } from '../../../services/game.service';
import { ResultService } from '../../../services/result.service';
import { Player } from '../../../models/player';
import { Result } from '../../../models/result';
import {
  KeyboardShortcutsComponent,
  ShortcutInput,
} from 'ng-keyboard-shortcuts';
import { TimeHelper } from '../../../helpers/time-helper';

@Component({
  selector: 'app-game-score-pad-list',
  templateUrl: './game-score-pad-list.component.html',
  styleUrls: ['./game-score-pad-list.component.scss'],
})
export class GameScorePadListComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Output() eventAddPointsStarted = new EventEmitter<Player>();
  @ViewChild(KeyboardShortcutsComponent)
  private keyboard?: KeyboardShortcutsComponent;

  @ViewChild('scroller')
  scroller?: ElementRef;

  private timerRef: any;
  private timerCounter: number | null = null;
  private timeHelper = new TimeHelper();

  gameResult: Result | null = null;
  timer: any;
  turnTime = '';

  constructor(
    public gameService: GameService,
    public resultService: ResultService
  ) {}

  shortcuts: ShortcutInput[] = [];
  ngOnInit(): void {
    this.refreshResult();
    this.calculateTurnTime();
    this.timer = setInterval(() => {
      this.calculateTurnTime();
    }, 1000);
  }
  ngAfterViewInit(): void {
    this.keyMappings();
    this.scrollToButtons();
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  private scrollToButtons(): void {
    console.log('scroll');
    if (this.scroller) {
      this.scroller.nativeElement.scrollIntoView();
    }
  }

  private keyMappings(): void {
    this.shortcuts.push(
      {
        key: ['ins', 'enter'],
        preventDefault: true,
        command: (e) => this.tableClick(),
      },
      {
        key: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-'],
        preventDefault: false,
        command: (e) => this.tableClick(),
      },
      {
        key: ['del'],
        preventDefault: true,
        command: (e) => this.undoLastEntry(),
      }
    );
  }

  refreshResult(): void {
    this.gameResult = this.resultService.getResultFromGame(
      this.gameService.game
    );
  }

  addPoints(player: Player): void {
    this.eventAddPointsStarted.emit(player);
  }

  tableClick(): void {
    this.eventAddPointsStarted.emit(this.gameService.currentPlayer);
  }

  endGame(): void {
    this.gameService.endGame();
  }

  undoLastEntry(): void {
    this.gameService.undoLastEntry();
    this.refreshResult();
  }

  private getTurnTime(): string {
    const res = this.timeHelper.getTurnTime(
      this.gameService.game.lastTurnDate,
      new Date()
    );

    return res;
  }

  changeShowDetailInScorePad(): void {
    this.gameService.toggleShowDetailInScorePad();
  }

  changeShowTimerInScorePad(): void {
    this.gameService.toggleShowTimerInScorePad();
  }

  private calculateTurnTime(): void {
    this.turnTime = this.getTurnTime();
  }
}
