export class TimeHelper {
  getTurnTime(startDate: Date, endDate: Date): string {
    const time = new Date(endDate).getTime() - new Date(startDate).getTime();

    let res = new Date(time).toISOString().substr(11, 8);
    res = this.cutZeros(res); // hh:

    return res;
  }

  getTurnTimeFromSeconds(seconds: number): string {
    let res = new Date(seconds * 1000).toISOString().substr(11, 8);
    res = this.cutZeros(res); // hh:

    return res;
  }

  private cutZeros(res: string): string {
    if (res.substr(0, 3) === '00:') {
      res = res.substr(3);
    }
    return res;
  }
}
