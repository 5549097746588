import { Component, OnInit } from '@angular/core';
import { GameService } from '../../services/game.service';
import { ResultService } from '../../services/result.service';
import { Summary } from '../../models/summary';

@Component({
  selector: 'app-game-summary',
  templateUrl: './game-summary.component.html',
  styleUrls: ['./game-summary.component.scss'],
})
export class GameSummaryComponent implements OnInit {
  summary: Array<Summary>;

  constructor(
    public gameService: GameService,
    private resultService: ResultService
  ) {
    this.summary = resultService.GetSummaryFromGame(gameService.game);
  }

  ngOnInit(): void {}

  continueGame(): void {
    this.gameService.continueGame();
  }

  startNewGame(): void {
    this.gameService.settingNewGame();
  }

  invertedScoresStateChange(): void {
    this.gameService.changeInvertedScore();
    this.summary = this.resultService.GetSummaryFromGame(this.gameService.game);
  }
}
