import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { GameSettingsComponent } from './pages/game-settings/game-settings.component';
import { GameScorePadComponent } from './pages/game-score-pad/game-score-pad.component';
import { GameScorePadAddPointsComponent } from './pages/game-score-pad/game-score-pad-add-points/game-score-pad-add-points.component';
import { GameScorePadListComponent } from './pages/game-score-pad/game-score-pad-list/game-score-pad-list.component';
import { GameSummaryComponent } from './pages/game-summary/game-summary.component';
import { FormsModule } from '@angular/forms';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { NgxPrettyCheckboxModule } from 'ngx-pretty-checkbox';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GameSettingsInformationComponent } from './pages/game-settings/game-settings-information/game-settings-information.component';

@NgModule({
  declarations: [
    AppComponent,
    GameSettingsComponent,
    GameScorePadComponent,
    GameScorePadListComponent,
    GameScorePadAddPointsComponent,
    GameSummaryComponent,
    GameScorePadAddPointsComponent,
    GameSettingsInformationComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    KeyboardShortcutsModule.forRoot(),
    NgxPrettyCheckboxModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
