<h1>result of the competition</h1>
<table>
  <tr *ngFor="let playerSummary of summary; let i = index">
    <td>
      <span *ngIf="summary[i - 1]?.place !== playerSummary.place">
        {{ playerSummary.place }}
      </span>
    </td>
    <td>{{ playerSummary.player.name }}</td>
    <td>{{ playerSummary.points }}</td>
    <td>{{ playerSummary.timeInfo }}</td>
  </tr>
</table>

<div class="options">
  <p-checkbox
    [checked]="gameService.game.invertedScores"
    (click)="invertedScoresStateChange()"
  >
    inverted scores (less-best)
  </p-checkbox>
</div>

<button class="button-c2" (click)="startNewGame()">Start new game</button>
<button (click)="continueGame()">Continue playing</button>
