<app-game-score-pad-list
  (eventAddPointsStarted)="addPointStarted($event)"
  *ngIf="state === eGameScorePadStateType.List"
></app-game-score-pad-list>
<app-game-score-pad-add-points
  [currentPlayer]="currentPlayer"
  (eventAddPoints)="addPointsAddPoints($event)"
  (eventCancel)="addPointsCancel()"
  (eventPassed)="addPointsPassed()"
  *ngIf="state === eGameScorePadStateType.AddPoints"
></app-game-score-pad-add-points>
